<template>
  <v-app class="course-layout">
    <v-navigation-drawer v-model="drawer" app color="grey darken-3" dark>
      <v-img :src="course.img" width="100%" />
      <v-list>
        <v-list-item>
          <v-list-item-avatar color="white">
            <v-icon color="primary">person</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ user.displayName }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list>
        <v-list-item>
          <v-list-item-title class="primary--text font-weight-bold">{{
            course.name.toLocaleUpperCase("tr-TR")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          link
          @click.prevent="goTo(item)"
          :to="item.link"
          :exact="item.exact"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item link :to="{ name: 'learner-home' }">
          <v-list-item-action>
            <v-icon>first_page</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Eğitimlerime Dön</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click.prevent="signOut()">
          <v-list-item-action>
            <v-icon>exit_to_app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Çıkış</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app elevate-on-scroll>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="mr-12 align-center">
        <span class="title">{{ title }}</span>
      </v-toolbar-title>
      <v-spacer />
      <div class="col-3 .col-md-4 pa-0">
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-progress-linear
              :value="courseRegistration.progress"
              color="primary"
              height="8"
              background-color="grey lighten-2"
              class="rounded-xl"
              v-on="on"
              v-bind="attrs"
            />
          </template>
          <span>%{{ courseRegistration.progress }} tamamlandı</span>
        </v-tooltip> -->
      </div>
    </v-app-bar>

    <v-main class="content">
      <router-view :course="course" :courseRegistration="courseRegistration" />
    </v-main>
  </v-app>
</template>

<script>
import firebase from "@/plugins/firebase";

export default {
  data: () => ({
    drawer: null,
    title: "",
    currentMenuItem: {},
    menuItems: [
      {
        icon: "home",
        title: "Giriş",
        link: { name: "course-home" },
        exact: true
      },
      {
        icon: "play_circle_filled",
        title: "Dersler",
        link: { name: "course-lessons" },
        exact: false
      }
      // ,{
      //   icon: "chat",
      //   title: "Tartışmalar",
      //   link: { name: "course-discussions" },
      //   exact: false
      // }
    ],
    course: {
      id: "",
      name: "",
      img: "",
      urlCode: "",
      desc: "",
      disabled: false,
      startDate: null,
      finishDate: null,
      price: 0,
      instructor: ""
    },
    courseRegistration: {
      id: "",
      uid: "",
      courseId: "",
      startDate: null,
      finishDate: null
    }
  }),
  computed: {
    userSignedIn() {
      return this.$store.getters["auth/userSignedIn"];
    },
    user() {
      return this.$store.getters["auth/user"];
    }
  },
  methods: {
    signOut() {
      this.$store.dispatch("auth/signOut");
      this.$router.push({ name: "home" }).catch(() => {
        return false;
      });
    },

    goTo(item) {
      this.currentMenuItem = item;
      this.title = item.title;
      this.$router.push(item.link).catch(() => {
        return false;
      });
    },

    async getCourseRegistration() {
      const regQs = await firebase
        .firestore()
        .collection("/apps/kahev-akademi/courseRegs")
        .where("uid", "==", this.user.id)
        .where("courseId", "==", this.course.id)
        .get();

      if (!regQs.empty) {
        const reg = regQs.docs[0];

        this.courseRegistration.id = reg.id;
        this.courseRegistration.uid = reg.data().uid;
        this.courseRegistration.courseId = reg.data().courseId;
        this.courseRegistration.startDate = reg.data().startDate.toDate();
        this.courseRegistration.finishDate = reg.data().finishDate
          ? reg.data().finishDate.toDate()
          : null;
      } else {
        this.$router.push({ name: "learner-home" });
      }
    },

    async getCourse() {
      try {
        // Ders bilgilerini al
        const courseQs = await firebase
          .firestore()
          .collection("/apps/kahev-akademi/courses")
          .where("urlCode", "==", this.$route.params.courseUrlCode)
          .limit(1)
          .get();

        if (!courseQs.empty) {
          const course = courseQs.docs[0];

          this.course.id = course.id;
          this.course.name = course.data().name;
          this.course.urlCode = course.data().urlCode;
          this.course.img = course.data().img;
          this.course.desc = course.data().desc;
          this.course.startDate = course.data().startDate.toDate();
          this.course.finishDate = course.data().finishDate
            ? course.data().finishDate.toDate()
            : null;
          this.course.instructor = course.data().instructor;
          this.course.price = course.data().price;

          // Kullanıcının ders kayıt bilgilerini al
          await this.getCourseRegistration();
        }
      } catch (err) {
        this.$notify(
          `Ders bilgileri alınamadı. Lütfen daha sonra tekrar deneyiniz.`
        );

        throw err;
      }
    }
  },
  async mounted() {
    this.currentMenuItem = this.menuItems[0];
    this.title = this.currentMenuItem.title;

    await this.getCourse();
  }
};
</script>

<style lang="scss" scoped>
.course-layout {
  .content {
    background-image: url("../../assets/bg-waves.svg");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
  }
}
</style>
